<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Newsletter</h4>
            <p>
              Prijavite se na naš Newsletter da biste dobijali najnovije
              informacije u vezi obnovljivih izvora energije
            </p>
            <form @submit.prevent="handleSubmit">
              <input
                v-model="newsletterForm.email"
                id="newsletter-email"
                type="email"
                name="email"
                aria-label="Email"
                required
              /><input type="submit" value="Prijavi se" :disabled="formSubmited" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>DND Solar<span>.</span></h3>
            <p>
              <strong>Telefon</strong>: <a href="tel:+38765602603">065/602-603</a><br>
              <strong>Email</strong>: <a href="mailto:office@dndcommerce.net">office@dndcommerce.net</a><br><br>
              <strong>Poslovno ime</strong>: "DND Commerce" Nikola Despetović
              s.p., Rogatica <br />
              <strong>JIB:</strong> 4512262240008<br />
              <strong>Tekući račun:</strong> 555-700-00562260-09<br />
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Korisni Linkovi</h4>
            <ul>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="https://svezadomacinstvo.com/">Sve za domaćinstvo</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="https://dndsolar.com/">Solarni paneli</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i> <a href="#">Usluge</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Uslovi korišćenja</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Politika privatnosti</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Naše Usluge</h4>
            <ul>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Solarni Paneli Sarajevo</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Solarni Paneli Banja Luka</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Solarni Paneli Tuzla</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Solarni Paneli Mostar</a>
              </li>
              <li>
                <i class="icofont-curved-right"></i>
                <a href="#">Solarni Paneli Trebinje</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Naše društvene mreže</h4>
            <p>
              Pratite nas na društvenim mrežama da bi bili u toku sa najnovijim
              informacijama
            </p>
            <div class="social-links mt-3">
              <a
                href="https://www.facebook.com/svezadomacinstvo1"
                class="facebook"
                aria-label="Facebook Icon"
                target="_blank"
                ><i class="icofont-facebook"></i
              ></a>
              <a
                href="https://www.instagram.com/svezadomacinstvo_com/"
                class="instagram"
                aria-label="Instagram Icon"
                target="_blank"
                ><i class="icofont-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/company/dndcommerce"
                class="linkedin"
                aria-label="LinkedIn Icon"
                target="_blank"
                ><i class="icofont-linkedin"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="copyright">
        &copy; Copyright <strong><span>DND Commerce</span></strong
        >. Sva prava zadržana
      </div>
      <div class="credits">
        Dizajn <a href="https://despetovic.rs/" target="_blank">Despetovic</a>
      </div>
    </div>
  </footer>
  <!-- End Footer -->

  <a
    href="tel:+38765602603"
    class="call__button call-link"
    aria-label="Phone button"
  >
    <figure class="icon__viber">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 6 36 36">
        <path
          fill="#0f0"
          d="M13,42h22c3.866,0,7-3.134,7-7V13c0-3.866-3.134-7-7-7H13c-3.866,0-7,3.134-7,7v22 C6,38.866,9.134,42,13,42z"
        />
        <path
          fill="#fff"
          d="M35.45,31.041l-4.612-3.051c-0.563-0.341-1.267-0.347-1.836-0.017c0,0,0,0-1.978,1.153 c-0.265,0.154-0.52,0.183-0.726,0.145c-0.262-0.048-0.442-0.191-0.454-0.201c-1.087-0.797-2.357-1.852-3.711-3.205 c-1.353-1.353-2.408-2.623-3.205-3.711c-0.009-0.013-0.153-0.193-0.201-0.454c-0.037-0.206-0.009-0.46,0.145-0.726 c1.153-1.978,1.153-1.978,1.153-1.978c0.331-0.569,0.324-1.274-0.017-1.836l-3.051-4.612c-0.378-0.571-1.151-0.722-1.714-0.332 c0,0-1.445,0.989-1.922,1.325c-0.764,0.538-1.01,1.356-1.011,2.496c-0.002,1.604,1.38,6.629,7.201,12.45l0,0l0,0l0,0l0,0 c5.822,5.822,10.846,7.203,12.45,7.201c1.14-0.001,1.958-0.248,2.496-1.011c0.336-0.477,1.325-1.922,1.325-1.922 C36.172,32.192,36.022,31.419,35.45,31.041z"
        />
      </svg>
    </figure>
  </a>
  <a
    href="viber://chat?number=%2B38765602603"
    class="call__button_viber call-link"
    aria-label="Viber button"
  >
    <figure class="icon__viber">
      <svg
        width="4rem"
        height="4rem"
        viewBox="0 0 99 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M99 0H0V99H99V0Z" fill="#7D3DAF"></path>
        <path
          d="M80.8099 31.9118L80.7904 31.8336C79.2089 25.4404 72.0793 18.5802 65.5319 17.153L65.458 17.1378C54.8679 15.1175 44.1301 15.1175 33.5421 17.1378L33.4661 17.153C26.9208 18.5802 19.7912 25.4404 18.2076 31.8336L18.1902 31.9118C16.2351 40.8401 16.2351 49.8944 18.1902 58.8227L18.2076 58.9009C19.7237 65.0212 26.3223 71.5662 32.6254 73.3662V80.5028C32.6254 83.0857 35.7731 84.3543 37.5631 82.4905L44.7937 74.9744C46.3619 75.0622 47.931 75.1111 49.5001 75.1111C54.831 75.1111 60.164 74.6071 65.458 73.5969L65.5319 73.5817C72.0793 72.1545 79.2089 65.2943 80.7904 58.9011L80.8099 58.8229C82.765 49.8944 82.765 40.8401 80.8099 31.9118ZM75.088 57.528C74.0322 61.6989 68.6188 66.8842 64.3176 67.8422C58.6866 68.913 53.0112 69.3707 47.3414 69.2136C47.2287 69.2106 47.1203 69.2542 47.0416 69.335C46.237 70.161 41.7622 74.7546 41.7622 74.7546L36.1467 80.5178C35.7361 80.9457 35.0149 80.6546 35.0149 80.0638V68.2415C35.0149 68.0462 34.8755 67.8802 34.6837 67.8426C34.6826 67.8424 34.6815 67.8422 34.6804 67.842C30.3792 66.884 24.9679 61.6986 23.91 57.5278C22.1504 49.4575 22.1504 41.2765 23.91 33.2063C24.9679 29.0354 30.3792 23.8501 34.6804 22.8921C44.5146 21.0217 54.4856 21.0217 64.3176 22.8921C68.6209 23.8501 74.0322 29.0354 75.088 33.2063C76.8495 41.2767 76.8495 49.4578 75.088 57.528Z"
          fill="white"
        ></path>
        <path
          d="M58.8566 62.9854C58.1954 62.7847 57.5652 62.6498 56.9799 62.4069C50.9156 59.8909 45.3345 56.645 40.9136 51.6693C38.3995 48.8398 36.4318 45.6454 34.7685 42.2648C33.9797 40.6616 33.315 38.9957 32.6374 37.3406C32.0196 35.8312 32.9296 34.2719 33.8878 33.1347C34.7869 32.0674 35.9439 31.2506 37.1969 30.6487C38.1749 30.1788 39.1396 30.4497 39.8539 31.2787C41.398 33.0708 42.8163 34.9547 43.9648 37.0321C44.6711 38.3099 44.4773 39.8718 43.1973 40.7413C42.8863 40.9527 42.6028 41.2008 42.313 41.4395C42.0588 41.6487 41.8196 41.8601 41.6454 42.1434C41.327 42.6617 41.3118 43.273 41.5168 43.8365C43.0952 48.1742 45.7557 51.5472 50.1217 53.3639C50.8203 53.6546 51.5219 53.993 52.3268 53.8992C53.6747 53.7417 54.1111 52.2632 55.0557 51.4907C55.9789 50.7358 57.1587 50.7258 58.1532 51.3552C59.1479 51.9847 60.1122 52.6605 61.0707 53.3435C62.0115 54.0139 62.9482 54.6697 63.8161 55.4339C64.6504 56.1688 64.9378 57.1329 64.468 58.1302C63.6077 59.9567 62.356 61.4763 60.5504 62.4462C60.0405 62.7197 59.4316 62.8084 58.8566 62.9854C59.4316 62.8084 58.1954 62.7845 58.8566 62.9854Z"
          fill="white"
        ></path>
        <path
          d="M49.5165 28.5599C57.4488 28.7822 63.9638 34.0464 65.36 41.8885C65.5979 43.2247 65.6826 44.5909 65.7884 45.9471C65.8329 46.5175 65.5099 47.0595 64.8942 47.0669C64.2584 47.0745 63.9723 46.5425 63.931 45.9723C63.8494 44.8435 63.7927 43.7098 63.6371 42.5904C62.816 36.6814 58.1042 31.7928 52.2226 30.7438C51.3376 30.5859 50.4317 30.5444 49.5352 30.4503C48.9684 30.3908 48.2262 30.3565 48.1006 29.652C47.9952 29.0613 48.4938 28.591 49.0562 28.5608C49.2091 28.5523 49.3629 28.5593 49.5165 28.5599C49.3629 28.5593 57.4488 28.7822 49.5165 28.5599Z"
          fill="white"
        ></path>
        <path
          d="M61.5713 44.1872C61.5583 44.2862 61.5513 44.5191 61.4933 44.7385C61.2828 45.5349 60.0754 45.6346 59.7974 44.8308C59.715 44.5923 59.7027 44.321 59.7022 44.0642C59.6994 42.3841 59.3342 40.7058 58.487 39.244C57.6161 37.7416 56.2856 36.4786 54.725 35.7144C53.7813 35.2523 52.7608 34.9652 51.7265 34.794C51.2744 34.7193 50.8176 34.6738 50.3634 34.6106C49.8129 34.5342 49.519 34.1833 49.5451 33.6409C49.5694 33.1326 49.9409 32.767 50.4948 32.7983C52.3152 32.9017 54.0735 33.2951 55.6919 34.1521C58.983 35.8949 60.8629 38.646 61.4116 42.3194C61.4364 42.4858 61.4764 42.6507 61.489 42.8177C61.52 43.2302 61.5396 43.6432 61.5713 44.1872C61.5396 43.6432 61.5583 44.2862 61.5713 44.1872Z"
          fill="white"
        ></path>
        <path
          d="M56.6376 43.9948C55.9739 44.0067 55.6188 43.6394 55.5503 43.0309C55.5028 42.6066 55.4652 42.1767 55.3639 41.764C55.1645 40.9511 54.7325 40.1977 54.0486 39.6996C53.7258 39.4644 53.36 39.293 52.9768 39.1824C52.49 39.0418 51.9844 39.0805 51.4987 38.9615C50.9713 38.8322 50.6795 38.4051 50.7625 37.9103C50.8379 37.4599 51.2761 37.1085 51.7681 37.1441C54.8435 37.3661 57.0414 38.956 57.3551 42.5764C57.3773 42.8319 57.4033 43.1019 57.3466 43.3463C57.2495 43.7647 56.9398 43.9743 56.6376 43.9948C56.9398 43.9743 55.9739 44.0067 56.6376 43.9948Z"
          fill="white"
        ></path>
      </svg>
    </figure>
  </a>
  <Modal v-if="showModal" :title="'Prijava na newsletter'" @close-modal-event="showModal = false">
    <p>{{ responseMessage }}</p>
  </Modal>
</template>

<script>
import axios from "axios";
import Modal from "../layout/Modal";

export default {
  name: "Footer",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      newsletterForm: {
        email: "",
      },
      responseMessage: "",
      formSubmited: false
    };
  },
  methods: {
    handleSubmit() {
      const postData = {
        fingerprint: this.$fingerprint,
        ...this.newsletterForm,
      };

      axios
        .post("/newsletter", postData)
        .then((res) => {
          this.responseMessage = res.data.message;
          this.formSubmited = true;
          this.showModal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #444444;
  font-size: 14px;
  background: #f1f6fe;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f1f6fe;
  text-align: center;
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #f57d33;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #f4975d;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #f48f51;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #f57d33;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #f57d33;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #f48f51;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ff9858;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  text-align: center;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}

@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}

/*--------------------------------------------------------------
# Phone Widget
--------------------------------------------------------------*/
@media (max-width: 768px) {
  .phone-widget {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    right: 0;
    background-color: red;
    z-index: 998;
  }

  .phone-widget-number {
    font-size: 25px;
    font-weight: 700;
    padding: 10px 0;
    color: #fff;
    margin: 0;
  }
}

.call__button {
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.call__button_viber {
  position: fixed;
  right: 2rem;
  bottom: 6rem;
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.icon__viber {
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
