<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <h1>Izračunajte uštedu uz <span>Solarni Kalkulator</span></h1>
      <h2>
        Zaštite se od povećanja troškova električne energije i počnite sa
        uštedom na solarnoj energiji od prvog dana, uštedite 30 - 100% u
        narednih 20 godina.
      </h2>
      <div class="d-flex">
        <a @click="scroll('step-one')" class="btn-get-started scrollto"
          >Izračunajte uštedu</a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
  components: {},
  data() {
    return {};
  },
  methods: {
    async scroll(id) {
      await this.$nextTick();

      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 75vh;
  background: url("../assets/images/solarni-paneli.webp") top left;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #3f3f3f;
  font-family: "Montserrat", sans-serif;
}

#hero h1 span {
  color: #f57d33;
}

#hero h2 {
  background-color: rgb(0, 0, 0, 0.3);
  color: #fff;
  padding: 20px;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 600;
}

#hero .btn-get-started {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #f57d33;
}

#hero .btn-get-started:hover {
  background: #f48f51;
}

#hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
}

#hero .btn-watch-video i {
  color: #106eea;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

#hero .btn-watch-video:hover {
  color: #106eea;
}

#hero .btn-watch-video:hover i {
  color: #3b8af2;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .btn-get-started,
  #hero .btn-watch-video {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}
</style>
