<template>
  <section id="results" class="results">
    <div class="container">
      <div class="section-title">
        <h2>Rezultati</h2>
        <h3>REZULTATI VAŠE <span>UŠTEDE</span></h3>
        <p>
          Vjerujemo da smo pružili sve informacije koje su potrebne. Hvala što
          se zanimate za naše usluge.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="results-box">
            <div class="row">
              <div class="col-12">
                <div>
                  <p class="text-center h4">
                    Krov podržava: {{ data.nominalPower }} kWh
                  </p>
                  <p class="text-center h4">
                    Godišnja proizvodnja iznosi: {{ data.annualProduction }} kWh
                  </p>
                  <p class="text-center h4">Godišnja ušteda iznosi:</p>
                  <div class="row justify-content-center my-4">
                    <div class="col-md-6">
                      <div class="stamp mx-auto">
                        <span class="annual-savings">{{ data.annualSavings }}%</span>
                      </div>
                      <div class="text-center mt-3">
                        <p>
                          * Sve informacije su bez garancije, stvarna ušteda
                          može se razlikovati. Za detaljno planiranje
                          konsultujte se sa DND Solar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <Contact :additional_data="data" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Contact from "./Contact";

export default {
  name: "Results",
  components: { Contact },
  props: ["data"],
  data() {
    return {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    };
  },
  methods: {
  },
};
</script>

<style>
.results {
  padding: 0 0 60px 0;
}

.results .results-box {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
    transform-origin: 50% 50%;
    transform: rotate(-2deg) scale(5);
    transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(-0deg) scale(1);
  }
}

.stamp {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  position: relative;
  display: block;
  background-color: #f57d33;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  animation-delay: 2.5s;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.results .annual-savings {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 50px;
}
</style>
