<template>
  <Navbar />

  <Calculator></Calculator>

  <Footer />
</template>

<script>
import Navbar from "./components/layout/Navbar.vue";
import Footer from "./components/layout/Footer.vue";
import Calculator from "./components/Calculator.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Calculator,
  },
  data() {
    return {};
  },
  mounted() {
    this.$gtm.trackView('HomePage', window.location.pathname);
  },
};
</script>

<style></style>
