import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/icofont/icofont.min.css'
import './assets/style.css'
import Fingerprint2 from "./fingerprint";
import { createGtm } from '@gtm-support/vue-gtm';

const app = createApp(App);
// User fingerprint
const fpOptions = {excludeJsFonts: true, excludeFlashFonts: true};
const fp = new Fingerprint2(fpOptions);

fp.get(function(result) {
    app.config.globalProperties.$fingerprint = result;
});

app.use(
    createGtm({
      id: 'GTM-PN7HS3X',
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    }),
  );

app.mount('#app');
