<template>
    <div class="bootstrap-modal">
      <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ title }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div class="modal-footer">
              <button  type="button" class="btn btn-secondary" @click="closeModal">
                Zatvori
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ["title"],
    methods: {
      closeModal() {
        this.$emit("close-modal-event");
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    display: block;
  }
  </style>  