<template>
  <form @submit.prevent="handleSubmit" id="contact-form" class="contact-form">
    <div class="form-group">
      <input
        v-model="formData.name"
        type="text"
        class="form-control"
        name="name"
        id="name"
        placeholder="Unesite ime i prezime"
        required
      />
    </div>
    <div class="form-group">
      <input
        v-model="formData.email"
        type="text"
        class="form-control"
        name="email"
        id="email"
        placeholder="Unesite Email"
        required
      />
    </div>
    <div class="form-group">
      <input
        v-model="formData.phone_number"
        type="text"
        class="form-control"
        name="phone_number"
        id="phone_number"
        placeholder="Unesite broj telefona"
        required
      />
    </div>
    <div class="form-group">
      <textarea
        v-model="formData.message"
        class="form-control"
        name="message"
        rows="5"
        placeholder="Poruka"
        required
      ></textarea>
    </div>
    <div class="text-center">
      <button id="submitOrder" type="submit" :disabled="formSubmited">
        Kontaktirajte nas
      </button>
    </div>
  </form>
  <Modal v-if="showModal" :title="'Kontakt putem website-a'" @close-modal-event="showModal = false">
    <p>{{ responseMessage }}</p>
  </Modal>
</template>

<script>
import axios from "axios";
import Modal from "./layout/Modal";

export default {
  name: "Contact",
  components: {
    Modal,
  },
  props: ["additional_data"],
  data() {
    return {
      showModal: false,
      formData: {
        name: "",
        email: "",
        phone_number: "",
        message: "",
      },
      responseMessage: "",
      formSubmited: false
    };
  },
  methods: {
    handleSubmit() {
      const postData = {
        fingerprint: this.$fingerprint,
        ...this.formData,
        ...this.additional_data,
      };

      axios
        .post("/kontakt", postData)
        .then((res) => {
          this.responseMessage = res.data.message;
          this.formSubmited = true;
          this.showModal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #f57d33;
}

.contact-form input {
  padding: 10px 15px;
}

.contact-form textarea {
  padding: 12px 15px;
}

.contact-form button {
  background: #f57d33;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact-form button:hover {
  background: #f4975d;
}
</style>
