<template>
  <!-- ======= Hero Section ======= -->
  <Hero />
  <!-- End Hero -->

  <main id="main">
    <!-- ======= Step One Section ======= -->
    <section id="step-one" class="step-one">
      <div class="container">
        <div class="section-title">
          <h2>Solarni kalkulator</h2>
          <h3>IZRAČUNAJTE <span>UŠTEDU</span></h3>
          <p>
            Ukoliko želite da izračunate uštedu potrebno je da popunite sljedeća
            polja.
          </p>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <form id="step-one-form" class="step-one-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <label for="roof-quadrature"
                    >Kvadratura krova (izražena u m²)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    name="roof-quadrature"
                    id="roof-quadrature"
                    v-model="roofQuadrature"
                    @input="changeRoofQuadrature"
                    placeholder="Unesite kvadraturu krova u m&sup2"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label for="monthly-consumption"
                    >Mjesečna potrošnja (izražena u kWh)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    name="monthly-consumption"
                    id="monthly-consumption"
                    v-model="monthlyConsumption"
                    @input="changeMonthlyConsumption"
                    placeholder="Unesite prosečnu mesečnu potrošnju"
                  />
                </div>
                <div class="form-group my-4">
                  <label for="roof-utilization-slider"
                    >Iskorišćenost krova: {{ roofUtilization }}%</label
                  >
                  <input
                    type="range"
                    class="form-range range-slider"
                    min="0"
                    max="100"
                    step="10"
                    id="roof-utilization-slider"
                    v-model="roofUtilization"
                    @input="changeRoofUtilization"
                  />
                </div>
                <Alert
                  v-if="alert.show"
                  :type="alert.type"
                  :message="alert.message"
                />
                <div class="text-center">
                  <button
                    type="button"
                    v-if="!showStepTwo"
                    @click="showStepTwoBtnClick"
                  >
                    Sljedeći korak
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Step One Section -->

    <!-- ======= Step Two Section ======= -->
    <section v-if="showStepTwo" id="step-two" class="step-two" ref="step_two">
      <div class="container">
        <div class="section-title">
          <h2>Nagib i pozicija</h2>
          <p>
            Odaberite ispravan nagib i položaj krova
          </p>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="sliders">
              <div class="row">
                <div class="col-md-6 mb-5">
                  <h4 class="text-center">Nagib krova</h4>
                  <agile
                    :autoplay="false"
                    :dots="false"
                    :speed="1"
                    @after-change="changeRoofTilt($event)"
                  >
                    <div data-tilt="0">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/0.png"
                        alt="Nagib krova - 0"
                      />
                    </div>
                    <div data-tilt="5">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/5.png"
                        alt="Nagib krova - 5"
                      />
                    </div>
                    <div data-tilt="10">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/10.png"
                        alt="Nagib krova - 10"
                      />
                    </div>
                    <div data-tilt="15">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/15.png"
                        alt="Nagib krova - 15"
                      />
                    </div>
                    <div data-tilt="20">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/20.png"
                        alt="Nagib krova - 20"
                      />
                    </div>
                    <div data-tilt="25">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/25.png"
                        alt="Nagib krova - 25"
                      />
                    </div>
                    <div data-tilt="30">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/30.png"
                        alt="Nagib krova - 30"
                      />
                    </div>
                    <div data-tilt="35">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/35.png"
                        alt="Nagib krova - 35"
                      />
                    </div>
                    <div data-tilt="40">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/40.png"
                        alt="Nagib krova - 40"
                      />
                    </div>
                    <div data-tilt="45">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/45.png"
                        alt="Nagib krova - 45"
                      />
                    </div>
                    <div data-tilt="50">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/50.png"
                        alt="Nagib krova - 50"
                      />
                    </div>
                    <div data-tilt="55">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/55.png"
                        alt="Nagib krova - 55"
                      />
                    </div>
                    <div data-tilt="60">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/60.png"
                        alt="Nagib krova - 60"
                      />
                    </div>
                    <div data-tilt="65">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-tilt/65.png"
                        alt="Nagib krova - 65"
                      />
                    </div>
                    <template prevButton>prev</template>
                  </agile>
                  <div class="text-center">
                    <p class="h3">{{ roofTilt }}°</p>
                  </div>
                </div>

                <div class="col-md-6 mb-5">
                  <h4 class="text-center">Pozicija krova</h4>
                  <agile
                    :autoplay="false"
                    :dots="false"
                    :speed="1"
                    @after-change="changeRoofAlignment($event)"
                  >
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/n-en.png"
                        alt="Pozicija krova - sever"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/no-en.png"
                        alt="Pozicija krova - severoistok"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/o-en.png"
                        alt="Pozicija krova - istok"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/so-en.png"
                        alt="Pozicija krova - jugoistok"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/s-en.png"
                        alt="Pozicija krova - jug"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/sw-en.png"
                        alt="Pozicija krova - jugozapad"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/w-en.png"
                        alt="Pozicija krova - zapad"
                      />
                    </div>
                    <div class="slide">
                      <img
                        class="img-fluid"
                        src="../assets/images/roof-alignment/nw-en.png"
                        alt="Pozicija krova - severozapad"
                      />
                    </div>
                  </agile>
                  <div class="text-center">
                    <p class="h3">
                      {{
                        roofAlignment.charAt(0).toUpperCase() +
                          roofAlignment.slice(1)
                      }}
                    </p>
                  </div>
                </div>
                <div class="text-center mt-2 mb-4">
                  <button
                    class="count-savings"
                    v-if="!showResults"
                    @click="countSavingsBtnClick"
                  >
                    Izračunajte uštedu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Step Two Section -->

    <!-- ======= Results Section ======= -->
    <Results
      v-if="showResults"
      :data="{
        roofQuadrature: roofQuadrature,
        monthlyConsumption: monthlyConsumption,
        roofUtilization: roofUtilization,
        roofTilt: roofTilt,
        roofAlignment: roofAlignment,
        nominalPower: nominalPower,
        annualProduction: annualProduction,
        annualSavings: annualSavings,
      }"
    />
    <!-- End Results Section -->
  </main>
</template>

<script>
import axios from "axios";
import Calculator from "./Calculator";
import { VueAgile } from "vue-agile";
import Hero from "./Hero";
import Results from "./Results";
import Alert from "./layout/Alert";

export default {
  name: "Calculator",
  components: {
    agile: VueAgile,
    Hero,
    Results,
    Alert,
  },
  created() {
    this.calculator = new Calculator();
  },
  data() {
    return {
      roofQuadrature: 0,
      monthlyConsumption: 0,
      roofUtilization: 0,
      roofTilt: 0,
      roofAlignment: "sever",
      nominalPower: 0,
      annualProduction: 0,
      annualSavings: 0,
      roofTilts: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
      roofAlignments: [
        "sever",
        "severoistok",
        "istok",
        "jugoistok",
        "jug",
        "jugozapad",
        "zapad",
        "severozapad",
      ],
      showStepTwo: false,
      showResults: false,
      alert: {
        show: false,
        type: "danger",
        message: "",
      },
    };
  },
  methods: {
    async scroll(id) {
      await this.$nextTick();

      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    delayedAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.show = true;
      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    changeRoofQuadrature() {
      this.calculator.setRoofQuadrature(this.roofQuadrature);
      this.calculator.calculate();

      this.nominalPower = this.calculator.getNominalPower();
      this.annualProduction = this.calculator.getAnnualProduction();
      this.annualSavings = this.calculator.getAnnualSavings();
    },
    changeMonthlyConsumption() {
      this.calculator.setMonthlyConsumption(this.monthlyConsumption);
      this.calculator.calculate();

      this.nominalPower = this.calculator.getNominalPower();
      this.annualProduction = this.calculator.getAnnualProduction();
      this.annualSavings = this.calculator.getAnnualSavings();
    },
    changeRoofUtilization() {
      this.calculator.setRoofUtilization(this.roofUtilization);
      this.calculator.calculate();

      this.nominalPower = this.calculator.getNominalPower();
      this.annualProduction = this.calculator.getAnnualProduction();
      this.annualSavings = this.calculator.getAnnualSavings();
    },
    changeRoofTilt(event) {
      this.roofTilt = this.roofTilts[event.currentSlide];
      this.calculator.setCurrentRoofTilt(this.roofTilt);
      this.calculator.calculate();

      this.nominalPower = this.calculator.getNominalPower();
      this.annualProduction = this.calculator.getAnnualProduction();
      this.annualSavings = this.calculator.getAnnualSavings();
    },
    changeRoofAlignment(event) {
      this.roofAlignment = this.roofAlignments[event.currentSlide];
      this.calculator.setCurrentRoofAlignment(this.roofAlignment);
      this.calculator.calculate();

      this.nominalPower = this.calculator.getNominalPower();
      this.annualProduction = this.calculator.getAnnualProduction();
      this.annualSavings = this.calculator.getAnnualSavings();
    },
    showStepTwoBtnClick() {
      const errors = [];

      if (this.roofQuadrature == 0) {
        errors.push("Unesite kvadraturu krova. ");
      }

      if (this.monthlyConsumption == 0) {
        errors.push("Unesite mjesečnu potrošnju. ");
      }

      if (this.roofUtilization == 0) {
        errors.push("Izaberite iskorišćenost krova.");
      }

      if (errors.length == 0) {
        this.showStepTwo = true;
        this.scroll("step-two");
      } else {
        let message = "";

        for (const err of errors) {
          message += err;
        }

        this.delayedAlert("danger", message);
      }
    },
    countSavingsBtnClick() {
      const postData = {
        fingerprint: this.$fingerprint,
        roofQuadrature: this.roofQuadrature,
        monthlyConsumption: this.monthlyConsumption,
        roofUtilization: this.roofUtilization,
        roofTilt: this.roofTilt,
        roofAlignment: this.roofAlignment,
        nominalPower: this.nominalPower,
        annualProduction: this.annualProduction,
        annualSavings: this.annualSavings,
      };

      axios.post("/calculations", postData);

      this.showResults = true;
      this.scroll("results");
    },
  },
};
</script>

<style>
/*--------------------------------------------------------------
# Step One
--------------------------------------------------------------*/
.step-one .step-one-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.step-one .step-one-form .form-group {
  margin-bottom: 20px;
}

.step-one .step-one-form input,
.step-one .step-one-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.step-one .step-one-form input:focus,
.step-one .step-one-form textarea:focus {
  border-color: #f57d33;
}

.step-one .step-one-form input {
  padding: 10px 15px;
}

.step-one .step-one-form .range-slider {
  padding: 0;
}

.step-one .step-one-form textarea {
  padding: 12px 15px;
}

.step-one .step-one-form button {
  background: #f57d33;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.step-one .step-one-form button:hover {
  background: #f4975d;
}

/*--------------------------------------------------------------
# Step Two
--------------------------------------------------------------*/
.step-two {
  padding: 0 0 60px 0;
}

.step-two .sliders {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.step-two .sliders .count-savings {
  background: #f57d33;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
</style>
