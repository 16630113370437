class Calculator {
  constructor() {
    this.roofUtilization = 0;
    this.roofQuadrature = 0;
    this.monthlyConsumption = 0;
    this.annualConsumption = 0;
    this.nominalPower = 0;
    this.annualProduction = 0;
    this.annualSavings = 0;
    this.currentRoofTilt = "0";
    this.currentRoofAlignment = "sever";
    this.matrix = [
      //0   5     10      15    20     25     30     35      40     45     50     55      60    65
      [1, 1.049, 1.091, 1.126, 1.146, 1.173, 1.186, 1.191, 1.189, 1.179, 1.162, 1.138, 1.106, 1.067], // jug
      [1, 1.029, 1.050, 1.065, 1.072, 1.075, 1.070, 1.061, 1.046, 1.026, 0.999, 0.968, 1.090, 0.932], // jugozapad
      [1, 1.010, 1.009, 1.005, 0.992, 0.977, 0.954, 0.932, 0.931, 0.872, 0.837, 0.799, 0.757, 0.717], // zapad
      [1, 0.990, 0.968, 0.944, 0.911, 0.878, 0.839, 0.802, 0.761, 0.719, 0.674, 0.630, 0.583, 0.542], // severozapad
      [1, 0.970, 0.927, 0.884, 0.830, 0.780, 0.723, 0.637, 0.618, 0.566, 0.511, 0.461, 0.409, 0.368], // sever
      [1, 0.990, 0.968, 0.944, 0.911, 0.878, 0.839, 0.802, 0.761, 0.719, 0.674, 0.630, 0.583, 0.542], // severoistok
      [1, 1.010, 1.009, 1.005, 0.992, 0.977, 0.954, 0.932, 0.903, 0.872, 0.837, 0.799, 0.757, 0.717], // istok
      [1, 1.029, 1.050, 1.065, 1.072, 1.075, 1.070, 1.061, 1.046, 1.026, 0.999, 0.968, 0.932, 0.890]  // jugoistok
    ];
    this.roofTilts = {
      "0": 0,
      "5": 1,
      "10": 2,
      "15": 3,
      "20": 4,
      "25": 5,
      "30": 6,
      "35": 7,
      "40": 8,
      "45": 9,
      "50": 10,
      "55": 11,
      "60": 12,
      "65": 13,
    }; //stepenKrova
    this.roofAlignments = {
      jug: 0,
      jugozapad: 1,
      zapad: 2,
      severozapad: 3,
      sever: 4,
      severoistok: 5,
      istok: 6,
      jugoistok: 7,
    }; // stranaSveta
  }

  setRoofUtilization(roofUtilization) {
    this.roofUtilization = roofUtilization;
  }

  getRoofUtilization() {
    return this.roofUtilization;
  }

  setRoofQuadrature(roofQuadrature) {
    this.roofQuadrature = roofQuadrature;
  }

  getRoofQuadrature() {
    return this.roofQuadrature;
  }

  setMonthlyConsumption(monthlyConsumption) {
    this.monthlyConsumption = monthlyConsumption;
    this.calcAnnualConsumption();
  }

  getMonthlyConsumption() {
    return this.monthlyConsumption;
  }

  setNominalPower(nominalPower) {
    this.nominalPower = nominalPower;
  }

  getNominalPower() {
    return this.nominalPower;
  }

  setAnnualProduction(annualProduction) {
    this.annualProduction = annualProduction;
  }

  getAnnualProduction() {
    return this.annualProduction;
  }

  setAnnualSavings(annualSavings) {
    this.annualSavings = annualSavings;
  }

  getAnnualSavings() {
    return this.annualSavings;
  }

  getMatrix() {
    return this.matrix;
  }

  setCurrentRoofTilt(currentRoofTilt) {
    this.currentRoofTilt = currentRoofTilt;
  }

  getCurrentRoofTilt() {
    return this.currentRoofTilt;
  }

  setCurrentRoofAlignment(currentRoofAlignment) {
    this.currentRoofAlignment = currentRoofAlignment;
  }

  getCurrentRoofAlignment() {
    return this.currentRoofAlignment;
  }

  setAnnualConsumption(annualConsumption) {
    this.annualConsumption = annualConsumption;
  }

  getAnnualConsumption() {
    return this.annualConsumption;
  }

  calcAnnualConsumption() {
    this.setAnnualConsumption(this.monthlyConsumption * 12);
  }

  calculate() {
    const matrix = this.getMatrix();
    const roofTilts = this.roofTilts;
    const roofAlignments = this.roofAlignments;
    const roofUtilization = this.getRoofUtilization();
    const roofQuadrature = this.getRoofQuadrature();
    const annualConsumption = this.getAnnualConsumption();

    const j = roofTilts[this.currentRoofTilt]; // stepen
    const i = roofAlignments[this.currentRoofAlignment]; // strana
    const matrixParam = matrix[i][j];

    // Calc nominal power
    const nominalPower = Math.round(
      (roofUtilization * roofQuadrature) / 100 / 6.72
    );
    this.setNominalPower(nominalPower);

    // Calc annual production
    const annualProduction = Math.round(
      ((roofUtilization * roofQuadrature) / 100 / 6.72) *
        167.906666666667 *
        12 *
        matrixParam
    );
    this.setAnnualProduction(annualProduction);

    // Calc annual savings
    const annualSavings = Math.round(
      ((((roofUtilization * roofQuadrature) / 100 / 6.72) *
        167.906666666667 *
        12 *
        matrixParam) /
        annualConsumption) *
        100
    );
    this.setAnnualSavings(annualSavings);
  }
}

export default Calculator;
